import React, { useState } from 'react';

import { Link } from 'gatsby';
import { useMediaQuery } from '@hooks';

import './NavBar.en.scss';

import Logo from '/src/static/images/logo.png';
import LogoFace from '/src/static/images/menu/Symbol_1.png';

const NavBarEn = () => {
  const [isShowMenu, setIsShowMenu] = useState('');
  const media767 = useMediaQuery('max-width', '767px');
  const [open, setOpen] = useState(false);

  return (
    <nav
      id="nav-menu-en"
      className={isShowMenu ? 'showBar' : '' || open ? 'openMenu' : ''}
      onMouseEnter={!media767 ? () => setIsShowMenu(true) : null}
      onMouseLeave={() => setIsShowMenu(false)}
      role="presentation"
    >
      {media767 && (
        <Link className="logo-mobile" to="/">
          <picture className="logo-content">
            <i className="icon logo" aria-label="Entes"></i>
          </picture>
        </Link>
      )}

      <div className="nav-menu-content">
        {!media767 && (
          <Link to="/en">
            <picture className="logo">
              <img src={isShowMenu ? Logo : LogoFace} alt="Entes" />
            </picture>
          </Link>
        )}

        <ul className="menu">
          <li>
            <Link to="/en">
              <i
                className="icon home-mobile-en home"
                aria-label="home"
              ></i>
            </Link>
          </li>

          <li>
            <Link to="/en/pieces">
              <i
                className="icon pieces-mobile-en pieces"
                aria-label="pieces"
              ></i>
            </Link>
          </li>

          <li>
            <Link to="/en/bio">
              <i
                className="icon biography-mobile-en biography"
                aria-label="biography"
              ></i>
            </Link>
          </li>

          <li>
            <Link to="/en/store">
              <i
                className="icon shop-mobile-en shop"
                aria-label="Store"
              ></i>
            </Link>
          </li>

          <li>
            <Link to="/en/contact-us">
              <i
                className="icon contact-mobile-en contact"
                aria-label="contact"
              ></i>
            </Link>
          </li>
        </ul>

        <ul className="redes">
          <li>
            <a href="https://facebook.com/entesdmjc/" target="_blank" rel="noreferrer noopener nofollow">
              <i
                className="icon redes facebook"
                aria-label="facebook"
              ></i>
            </a>
          </li>

          <li>
            <a href="https://www.instagram.com/entesdmjc/" target="_blank" rel="noreferrer noopener nofollow">
              <i
                className="icon redes instagram"
                aria-label="facebook"
              ></i>
            </a>
          </li>
          <li>
            <a href=" https://twitter.com/entesdmjc/" target="_blank" rel="noreferrer noopener nofollow">
              <i
                className="icon redes twitter"
                aria-label="facebook"
              ></i>
            </a>
          </li>
          <li>
            <a href=" https://opensea.io/entestudio" target="_blank" rel="noreferrer noopener nofollow">
              <i
                className="icon redes opensea"
                aria-label="facebook"
              ></i>
            </a>
          </li>
        </ul>
      </div>
     

      {media767 && (
        <button
          className={`hamburger ${open ? 'open' : ''}`}
          onClick={() => setOpen(!open)}
          aria-label="menu hamburguesa"
        >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </button>
      )}
    </nav>
  );
};


export default NavBarEn;
