import React, { useRef, useState } from 'react';
import {Link} from 'gatsby';
import Slider from 'react-slick';
import Layout from '../layouts/es.jsx';
import arrowPrev from '/src/static/images/icons/slider--left--white.svg';
import arrowNext from '/src/static/images/icons/slider--right--white.svg';
import { ModalWant } from '@components/Modals';
import { SlickCustomDots } from '@components/shared';
import { navigate } from "gatsby";

const PiecesDetailPage = ({pageContext}) => {
  const {data, list } = pageContext;
  const video = data.fieldsPieces.imagenes.variables.video === null ? false : true;
  const slugList = list.map(el => {return el.slug})
  const indexOfMyPost = slugList.indexOf(data.slug); 
  const prevP =  (indexOfMyPost === 0 ? '/' : list[indexOfMyPost - 1]);
  const nextP =  (indexOfMyPost === (list.length - 1 ) ? '/' : list[indexOfMyPost + 1]);
  const modalWantRef = useRef();
  const sliderRef = useRef();
  const [ setSliderCurrent ] = useState(1);

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    dots: true,
    appendDots: dots => {
      return (<SlickCustomDots dots={dots} gallery={data.fieldsPieces.imagenes.gallery} featured={data.featuredImage.node} slider={sliderRef}/>);
    },
    beforeChange: (current, next) => setSliderCurrent(next + 1),
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          appendDots: dots => <ul>{dots}</ul>,
        }
      }
    ]
  };

  return (
    <Layout pageId="pieces-detail" scrollTop={false}>
      <div className="container">

        <div className="move-to-container">
          <div className="to-back">
            <Link className="btn btn--back" to="/piezas/" >
              <i className="icon icon-arrow-left--gray"></i>
              Regresar
            </Link>
          </div>

          <div className="navigate">
            <p>
              <span className="current">{indexOfMyPost + 1}</span>/
              <span className="total">{list.length}</span>
              </p>
            <Link to={'/piezas/' + prevP.slug} className={(prevP.slug === undefined ? 'hide-item': '')}>
              <button id="prev" type="button">
                <img src={arrowPrev} alt="Prev" />
              </button>
            </Link>
            <Link to={'/piezas/' + nextP.slug} className={(nextP.slug === undefined ? 'hide-item': '')}>
              <button id="next" type="button">
                <img src={arrowNext} alt="Next" />
              </button>
            </Link>
          </div>
        </div>

        <div className="row detail-row">
          <div className="col-lg-4 information">
            <div className="content">
              <h1>{data.title}</h1>
              <p className="data">
                {data.fieldsPieces.tecnica ? `Técnica:  ${data.fieldsPieces.tecnica}` : ''}
              </p>
              <p className="data">
                {data.fieldsPieces.medidas ? `Tamaño:  ${data.fieldsPieces.medidas}` : ''}
              </p>
              <p className="data">
                {data.fieldsPieces.place ? `Lugar:  ${data.fieldsPieces.place}` : ''}
              </p>
              <p className="data">
                {data.fieldsPieces.date ? `Fecha:  ${data.fieldsPieces.date}` : ''}
              </p>
              <p className="description" dangerouslySetInnerHTML={{__html: data.content}}>
              </p>
              {data.fieldsPieces.type == 'nft' ? 
              <>
                <a href={data.fieldsPieces.imagenes.variables.nftLink} style={{textDecorationLine: "none"}} target="_blank">
                  <button
                    className="btn btn--primary"
                    type="button"
                  >
                    {data.fieldsPieces.imagenes.variables.vendido ? 'Vendido' : 'Comprar NFT'}
                  </button>
                </a>
              </> : 
              <>
                <button
                  className="btn btn--primary"
                  type="button"
                  onClick={() => modalWantRef.current.openModal()}
                >
                  Quiero una obra
                </button>
              </>}
             
            </div>
          </div>

          <div className="col-xs-12 col-lg-8 image"> 
            {video == true ? 
            <> 
              <video style={{width: "100%"}} controls>
                <source src={data.fieldsPieces.imagenes.gallery[0].link} type="video/mp4"/>
              </video>
            </> :
            <>
              { data.fieldsPieces.imagenes.gallery ?
                (
                  <div className="custom_slick_slider">
                    <Slider {...settings} ref={sliderRef}>
                      {data.fieldsPieces.imagenes.gallery.map((v, index) => ( <>
                        <picture key={index}>
                          <img src={v.sourceUrl} alt={`Gallery Image (${index}) of ${data.title}`}/>
                        </picture>
                        </>))}
                    </Slider>
                  </div>
                ) :
                (
                  <img src={data.featuredImage.node.sourceUrl} alt="Imagen 2" className="just-featured"/>
                )
              }
            </>}
          </div>
        </div>
      </div>
      <ModalWant modalWantRef={modalWantRef} />
    </Layout>
  );
};

export default PiecesDetailPage;
