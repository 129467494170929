import { useEffect, useState } from 'react';

const isBrowser = typeof window !== 'undefined';

const useMediaQuery = (prefixes, resolution) => {
  const query = `(${prefixes}: ${resolution})`;

  const mediaInitial = isBrowser ? window.matchMedia(query).matches : null;

  const [matches, setMatches] = useState(mediaInitial);

  useEffect(() => {
    const media = window.matchMedia(query);

    if (media !== matches) setMatches(media.matches);

    const listener = () => setMatches(media.matches);

    window.addEventListener('resize', listener);

    return () => {
      window.removeEventListener('resize', listener);
    };
  }, [matches, query]);

  return matches;
};

export default useMediaQuery;
