import React from "react";
import './SlickCustomDots.scss'

const SlickCustomDots = ({dots, gallery, featured, slider, singleProduct}) => {
  const prevSlider = (dot) => {
    const elem = dot.target;
    const target = elem.getAttribute('data-dot-target')
    slider.current.slickGoTo(target);
  };
  return (
      <div className={`slider-custom-dots-img ${singleProduct ? 'single-product': ''}`}>
        { gallery? (
          gallery.map((el, i)=>(
            <div data-dot-target={i} className="dot" onClick={prevSlider} >
              <img data-dot-target={i} src={el.sourceUrl} alt="alt" className="dot-img"/>
            </div>
          ))
        ) : (<></>) }
      </div>
  )
}

export default SlickCustomDots;
