import React from 'react';
import PropTypes from 'prop-types';

import scrollTo from 'gatsby-plugin-smoothscroll';

import './Footer.scss';
import icoArrowScrollTop from '../../static/images/icons/arrow-scroll--top.svg';

const Footer = ({ scrollTop }) => {
  return (
    <footer id="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-10 mx-auto">
            <div className="elementos">
              {scrollTop && (
                <button className="backtop" onClick={() => scrollTo('main')}>
                  <img src={icoArrowScrollTop} alt="" />
                </button>
              )}

              <p>
                Copyright © Entes {new Date().getFullYear()} - All rights reserved
                Powered by{' '}
                <a href="https://whiz.pe" target="_blank" rel="noreferrer">
                  Whiz Latam
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  scrollTop: PropTypes.bool,
};

export default Footer;
